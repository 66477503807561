<template>
  <div class="view-home">
    <div class="description-block">
      <div class="container">
        <div class="description-wrapper">
          <div class="description-left">
            <div class="description-title">
              {{ $t('serviceQabilet') }}
            </div>
            <div class="description-subtitle">
              {{ $t('main-page.description') }}
            </div>
            <router-link :to="getTestedLink" class="description-btn">{{ $t('main-page.test-btn') }}</router-link>
<!--            <el-button round class="description-btn">Пройти тестирование</el-button>-->
<!--            <div class="description-left-back">-->
<!--              <el-image-->
<!--                  style="width: 100%; height: 100%"-->
<!--                  src="/images/main-back-figures.png"-->
<!--                  fit="contain"></el-image>-->
<!--            </div>-->
          </div>
          <div class="description-right">
            <el-image
                style="width: 100%; height: 100%"
                src="/images/main-back.png"
                fit="contain"></el-image>
          </div>
        </div>
      </div>
    </div>


    <section class="tests tests-new">
      <div class="container">
        <div class="section-title" style="margin-top: 0">
          {{ $t('main-page.test-types') }}
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="card" :style="{ 'background-image': `url(/images/test-back-1.png)` }">
              <div class="title" v-html="$t('aptitudeTests')">

              </div>
              <router-link :to="getAptitudeTestsLink" class="card-link">
                {{ $t('go') }}
              </router-link>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card" :style="{ 'background-image': `url(/images/test-back-2.png)` }">
              <div class="title" v-html="$t('careerTests')">

              </div>
              <router-link :to="getTestedLink" class="card-link">
                {{ $t('go') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>


    <section class="tests reasons">
      <div class="circle-blue"></div>
      <div class="circle-navy"></div>
      <div class="container">
        <div class="reasons-wrapper">
          <div class="reasons-left">
            <el-image
                style="width: 100%; height: 100%"
                src="/images/reason-left-img.png"
                fit="contain"></el-image>
          </div>
          <div class="reasons-right reasons-desktop">
            <router-link to="/about-us" class="title" v-html="$t('whyQabilet')">

            </router-link>
            <div class="reason-item">
              <div class="reason-item-left">
                <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.673 1.01017C9.94567 1.58179 4.81126 5.17304 2.19584 10.4371C-0.567173 15.998 -0.107972 22.6991 3.38855 27.8461C5.87423 31.505 9.38589 33.8647 14.0399 35.004C14.6048 35.1423 15.852 35.2135 17.5465 35.204C19.7064 35.192 20.3994 35.1278 21.4244 34.845C26.5931 33.4187 30.4575 30.2386 32.6827 25.5802C34.8619 21.0183 34.9746 16.0254 33.006 11.2484C31.6033 7.84471 28.3738 4.39724 25.0333 2.73808C22.1488 1.30548 18.8385 0.694403 15.673 1.01017ZM20.3699 5.92533C22.6678 6.43947 24.6795 7.58965 26.3408 9.33898C27.4833 10.5423 28.1798 11.5115 28.6814 12.5961C30.3089 16.1156 30.3203 19.8822 28.7134 23.3031C27.9928 24.8373 28.4506 25.1178 23.4141 20.0562C18.5602 15.1784 18.5133 15.1436 17.1456 15.4029C16.4153 15.5412 15.5216 16.444 15.3847 17.1818C15.1281 18.5634 15.1639 18.6127 19.9697 23.4892C22.5921 26.1502 24.3922 28.097 24.3278 28.2021C24.1624 28.4726 22.8087 29.1277 21.4942 29.5735C16.0103 31.4333 9.95603 29.1887 7.02853 24.2102C3.96219 18.9961 5.08573 12.3274 9.67503 8.50328C12.677 6.00182 16.5442 5.06967 20.3699 5.92533Z" fill="#02C7FA"/>
                </svg>
                <span>
                  {{ $t('whyQabilet-1') }}
                </span>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  {{ $t('whyQabilet-1-2') }}
                </div>
              </div>
            </div>
            <div class="reason-item">
              <div class="reason-item-left">
                <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.673 1.01017C9.94567 1.58179 4.81126 5.17304 2.19584 10.4371C-0.567173 15.998 -0.107972 22.6991 3.38855 27.8461C5.87423 31.505 9.38589 33.8647 14.0399 35.004C14.6048 35.1423 15.852 35.2135 17.5465 35.204C19.7064 35.192 20.3994 35.1278 21.4244 34.845C26.5931 33.4187 30.4575 30.2386 32.6827 25.5802C34.8619 21.0183 34.9746 16.0254 33.006 11.2484C31.6033 7.84471 28.3738 4.39724 25.0333 2.73808C22.1488 1.30548 18.8385 0.694403 15.673 1.01017ZM20.3699 5.92533C22.6678 6.43947 24.6795 7.58965 26.3408 9.33898C27.4833 10.5423 28.1798 11.5115 28.6814 12.5961C30.3089 16.1156 30.3203 19.8822 28.7134 23.3031C27.9928 24.8373 28.4506 25.1178 23.4141 20.0562C18.5602 15.1784 18.5133 15.1436 17.1456 15.4029C16.4153 15.5412 15.5216 16.444 15.3847 17.1818C15.1281 18.5634 15.1639 18.6127 19.9697 23.4892C22.5921 26.1502 24.3922 28.097 24.3278 28.2021C24.1624 28.4726 22.8087 29.1277 21.4942 29.5735C16.0103 31.4333 9.95603 29.1887 7.02853 24.2102C3.96219 18.9961 5.08573 12.3274 9.67503 8.50328C12.677 6.00182 16.5442 5.06967 20.3699 5.92533Z" fill="#02C7FA"/>
                </svg>
                <span>
                  {{ $t('whyQabilet-2') }}
                </span>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  {{ $t('whyQabilet-2-2') }}
                </div>
                <!--                <div class="reason-item-text">-->
                <!--                  Contrary to popular belief, Lorem Ipsum is not simply random text.-->
                <!--                </div>-->
              </div>
            </div>
            <div class="reason-item">
              <div class="reason-item-left">
                <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15.673 1.01017C9.94567 1.58179 4.81126 5.17304 2.19584 10.4371C-0.567173 15.998 -0.107972 22.6991 3.38855 27.8461C5.87423 31.505 9.38589 33.8647 14.0399 35.004C14.6048 35.1423 15.852 35.2135 17.5465 35.204C19.7064 35.192 20.3994 35.1278 21.4244 34.845C26.5931 33.4187 30.4575 30.2386 32.6827 25.5802C34.8619 21.0183 34.9746 16.0254 33.006 11.2484C31.6033 7.84471 28.3738 4.39724 25.0333 2.73808C22.1488 1.30548 18.8385 0.694403 15.673 1.01017ZM20.3699 5.92533C22.6678 6.43947 24.6795 7.58965 26.3408 9.33898C27.4833 10.5423 28.1798 11.5115 28.6814 12.5961C30.3089 16.1156 30.3203 19.8822 28.7134 23.3031C27.9928 24.8373 28.4506 25.1178 23.4141 20.0562C18.5602 15.1784 18.5133 15.1436 17.1456 15.4029C16.4153 15.5412 15.5216 16.444 15.3847 17.1818C15.1281 18.5634 15.1639 18.6127 19.9697 23.4892C22.5921 26.1502 24.3922 28.097 24.3278 28.2021C24.1624 28.4726 22.8087 29.1277 21.4942 29.5735C16.0103 31.4333 9.95603 29.1887 7.02853 24.2102C3.96219 18.9961 5.08573 12.3274 9.67503 8.50328C12.677 6.00182 16.5442 5.06967 20.3699 5.92533Z" fill="#02C7FA"/>
                </svg>
                <span>
                  {{ $t('whyQabilet-3') }}
                </span>
              </div>
              <div class="reason-item-right">
                <div class="reason-item-title">
                  {{ $t('whyQabilet-3-2') }}
                </div>
              </div>
            </div>
          </div>
<!--          <div class="reasons-mobile">-->
<!--            <div class="mobile-line-top"></div>-->
<!--            <div class="title">Почему <span>Qabilet</span></div>-->
<!--            <div class="reason-item" style="margin-left: 10px">-->
<!--              <div class="reason-item-top">-->
<!--                  Инновационный подход-->
<!--              </div>-->
<!--              <div class="reason-item-bottom">-->
<!--                Процесс профориентации более вдохновляющий и интересный для ребенка.-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="reason-item" style="margin-left: 10px">-->
<!--              <div class="reason-item-top">-->
<!--                Надежность и качество-->
<!--              </div>-->
<!--              <div class="reason-item-bottom">-->
<!--                Проверенные методики и качественные данные обеспечивают точные результаты и рекомендации для вашего ребенка.-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="reason-item" style="margin-left: 10px">-->
<!--              <div class="reason-item-top">-->
<!--                Embed analytics-->
<!--              </div>-->
<!--              <div class="reason-item-bottom">-->
<!--                At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores.-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
      </div>
    </section>

    <section class="news-main">
      <div class="container news-main-desktop">
        <div class="section-title" style="margin-top: 0">
          {{ $t('news') }}
        </div>
        <div class="subtitle">
          {{ $t('readOurNews') }}
        </div>
        <div class="row">
          <div class="col-lg-6" v-if="news[0]">
            <a :href="backUrlNews + news[0].id">
              <div class="news-left-img">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="backUrlImg + news[0].image"
                    fit="fit"></el-image>
              </div>
              <div class="heading">
                news
              </div>
              <div class="news-left-title">
                {{ (news[0][`title_${$i18n.locale}`] || news[0].title_ru ) | Numerize }}
              </div>
              <div class="news-left-text">
                {{ (news[0][`news_description_${$i18n.locale}`] || news[0].news_description_ru) | Numerize }}
              </div>
            </a>
            <a href="https://elumiti.kz/news" class="news-left-link">
              {{ $t('profile.calendar.more-btn') }}
            </a>
          </div>
          <div class="col-lg-6">
            <a class="news-right-item" v-if="news[1]" :href="backUrlNews + news[1].id">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="backUrlImg + news[1].image"
                    fit="fit"></el-image>
<!--                <div class="news-item-right-label">Counseling</div>-->
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  {{ (news[1][`title_${$i18n.locale}`] || news[1].title_ru ) | Numerize }}
                </div>
                <div class="news-item-left-text">
                  {{ (news[1][`news_description_${$i18n.locale}`] || news[1].news_description_ru) | Numerize }}
                </div>
              </div>
            </a>
            <a class="news-right-item" v-if="news[2]" :href="backUrlNews + news[2].id">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="backUrlImg + news[2].image"
                    fit="fit"></el-image>
<!--                <div class="news-item-right-label">Counseling</div>-->
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  {{ (news[2][`title_${$i18n.locale}`] || news[2].title_ru ) | Numerize }}
                </div>
                <div class="news-item-left-text">
                  {{ (news[2][`news_description_${$i18n.locale}`] || news[2].news_description_ru) | Numerize }}
                </div>
              </div>
            </a>
            <a class="news-right-item" v-if="news[3]" :href="backUrlNews + news[3].id">
              <div class="news-item-right">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="backUrlImg + news[3].image"
                    fit="fit"></el-image>
<!--                <div class="news-item-right-label">Counseling</div>-->
              </div>
              <div class="news-item-left">
                <div class="news-item-left-title">
                  {{ (news[3][`title_${$i18n.locale}`] || news[3].title_ru ) | Numerize }}
                </div>
                <div class="news-item-left-text">
                  {{ (news[3][`news_description_${$i18n.locale}`] || news[3].news_description_ru) | Numerize }}
                </div>
              </div>
            </a>
          </div>

        </div>
      </div>

      <div class="container news-main-mobile">
        <div class="section-title">
          {{ $t('news') }}
        </div>
        <carousel :perPage="perPageNews" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="false"
                  :paginationEnabled="false" class="carousel-mobile">
          <slide v-for="slide in news" class="mobile-slide">
            <div class="carousel-mobile-item">
              <el-image
                  style="width: 100%; height: 100%"
                  :src="backUrlImg + slide.image"
                  fit="cover"></el-image>
              <div class="date">
                {{formatDate(slide.updated_at)}}
              </div>
              <div class="title">
                {{(slide[`title_${$i18n.locale}`] || slide.title_ru) | Numerize}}
              </div>
              <a class="more-btn" :href="backUrlNews + slide.id">
                {{ $t('profile.calendar.more-btn') }}
              </a>
            </div>
          </slide>
        </carousel>
      </div>
    </section>


    <section class="main-tabs-block">
      <div class="container">
        <div class="main-tabs-wrapper">
          <div class="main-tabs">
            <ul>
              <li :class="{'active-tab' : currentTab == 'teachers' }" @click="currentTab='teachers'">
                {{ $t('breadcrumb.for-teachers') }}
              </li>
              <li :class="{'active-tab' : currentTab == 'parents' }" @click="currentTab='parents'">
                {{ $t('breadcrumb.for-parents') }}
              </li>
              <li :class="{'active-tab' : currentTab == 'events' }" @click="currentTab='events'">
                {{ $t('events-main') }}
              </li>
            </ul>
          </div>
          <div class="main-content for-parents">
            <div class="useful-block" v-if="currentTab == 'teachers'">
              <div class="useful-title">
                {{ $t('mainTeachers-1') }}
              </div>
              <div class="useful-text">
                {{ $t('mainTeachers-2') }}
              </div>
              <router-link class="reg-link" to="/for-teachers">
                {{ $t('profile.calendar.more-btn') }}
              </router-link>
            </div>
            <div class="cards-block" v-if="currentTab == 'parents'">
              <router-link :to="{ name: 'parents-list', params: { id: parentsList[0].id }}" class="card-item" v-if="this.parentsList[0]">
                <div class="card-item-img" :style="{ 'background-image': `url(${this.parentsList[0].image_path})` }">

                </div>
<!--                <div class="card-item-date">-->
<!--                  {{ $t('dateOfEvent') }}: {{ formattedDate(this.parentsList[0].date) }}-->
<!--                </div>-->
                <div class="card-item-title">
                  {{ parentsList[0][`title_${$i18n.locale}`] }}
                </div>
                <div class="card-item-text">
                  {{ parentsList[0][`description_${$i18n.locale}`] }}
                </div>
<!--                <div class="card-item-address">-->
<!--                  {{ $t('venue') }}: {{ parentsList[0][`place_${$i18n.locale}`] }}-->
<!--                </div>-->
              </router-link>
              <router-link :to="{ name: 'parents-list', params: { id: parentsList[1].id }}" class="card-item" v-if="this.parentsList[1]">
                <div class="card-item-img" :style="{ 'background-image': `url(${this.parentsList[1].image_path})` }">

                </div>
<!--                <div class="card-item-date">-->
<!--                  {{ $t('dateOfEvent') }}: {{ formattedDate(this.parentsList[1].date) }}-->
<!--                </div>-->
                <div class="card-item-title">
                  {{ parentsList[1][`title_${$i18n.locale}`] || this.parentsList[1].title_ru }}
                </div>
                <div class="card-item-text">
                  {{ parentsList[1][`description_${$i18n.locale}`] || this.parentsList[1].description_ru}}
                </div>
<!--                <div class="card-item-address">-->
<!--                  {{ $t('venue') }}: {{ parentsList[1][`place_${$i18n.locale}`] }}-->
<!--                </div>-->
              </router-link>
            </div>
            <div class="cards-block" v-if="currentTab == 'events'">
              <router-link :to="{ name: 'one-forum', params: { id: forums[0].id }}" class="card-item" v-if="this.forums[0]">
                <div class="card-item-img" :style="{ 'background-image': `url(${getImagePath(this.forums[0].banner)})` }">
                  <span>{{ $t('forum') }}</span>
                </div>
                <div class="card-item-date">
                  {{ $t('dateOfEvent') }}: {{ formattedDate(this.forums[0].date_from) }}
                </div>
                <div class="card-item-title card-event-title">
                  {{this.forums[0][`name_${$i18n.locale}`] || this.forums[0].name_ru}}
                </div>
                <div class="card-item-text card-event-text">
                  {{this.forums[0][`description_${$i18n.locale}`] || this.forums[0].description_ru}}
                </div>
                <div class="card-item-address">
                  {{ $t('venue') }}: {{ this.forums[0][`venue_${$i18n.locale}`] || this.forums[0].venue_ru}}
                </div>
              </router-link>
              <router-link :to="{ name: 'one-forum', params: { id: forums[1].id }}" class="card-item" v-if="this.forums[1]">
                <div class="card-item-img" :style="{ 'background-image': `url(${getImagePath(this.forums[1].banner)})` }">
                  <span>{{ $t('forum') }}</span>
                </div>
                <div class="card-item-date">
                  {{ $t('dateOfEvent') }}: {{ formattedDate(this.forums[1].date_from) }}
                </div>
                <div class="card-item-title card-event-title">
                  {{this.forums[1][`name_${$i18n.locale}`] || this.forums[1].name_ru}}
                </div>
                <div class="card-item-text card-event-text">
                  {{this.forums[1][`description_${$i18n.locale}`] || this.forums[1].description_ru}}
                </div>
                <div class="card-item-address">
                  {{ $t('venue') }}: {{ this.forums[1][`venue_${$i18n.locale}`] || this.forums[1].venue_ru}}
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="tests reviews-main">
      <div class="current-review">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="reviews-title" v-html="$t('reviewsQabilet')">

              </div>
              <div class="reviews-text">
                {{ $t('reviewsQabiletText') }}
              </div>
            </div>
            <div class="col-lg-6">
              <div class="current-review-card">
                <div class="card-img">
<!--                  <el-image-->
<!--                      style="width: 100%; height: 100%"-->
<!--                      :src="reviewPhotoPath + currentReview.photo"-->
<!--                      fit="fit"></el-image>-->
                  <div v-if="extractVideoId(currentReview.youtube)" class="card-youtube" @click.prevent="openVideoInModal(currentReview.youtube)">
                    <iframe class="card-youtube iframe-video"
                            :src="`https://www.youtube.com/embed/${extractVideoId(currentReview.youtube)}`"
                            title="YouTube video player" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </div>
                  <img v-else :src="reviewPhotoPath + currentReview.photo" width="100%" height="100%" alt="" style="border-radius: 20px"/>
                </div>
                <div class="card-text">
                  <div>
                    {{currentReview[`text_${$i18n.locale}`] || currentReview.text_ru}}
                  </div>
                  <div class="card-info">
                    <span>{{currentReview[`name_${$i18n.locale}`] || currentReview.name_ru}}, {{currentReview.age}} лет</span>
                    <span>{{ $t(`${currentReview.type}-review`) }}</span>
                    <span>{{currentReview.region}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <carousel :perPage="1" :loop="true" :autoplay="false" :autoplayTimeout="5000" :navigationEnabled="true"
                    :paginationEnabled="false" class="carousel-main-mobile">
            <slide v-for="(slide, index) in reviewsSlides" class="current-review-card-mobile">
              <div style="padding: 10px">
                <div class="card-top">
                  <div class="card-img">
                    <div v-if="extractVideoId(slide.youtube)" class="card-youtube" @click.prevent="openVideoInModal(slide.youtube)">
                      <iframe class="card-youtube iframe-video"
                              :src="`https://www.youtube.com/embed/${extractVideoId(slide.youtube)}`"
                              title="YouTube video player" frameborder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                    </div>
                    <el-image v-else
                              style="width: 100%; height: 100%"
                              :src="reviewPhotoPath + slide.photo"
                              fit="fit"></el-image>
                  </div>
                  <div class="card-info">
                    <p class="name">
                      {{slide[`name_${$i18n.locale}`] || slide.name_ru}}, {{slide.age}} лет
                    </p>
                    <p>
                      <span>{{ $t(`${slide.type}-review`) }}</span>
                    </p>
                    <p>
                      {{slide.region}}
                    </p>
                  </div>
                </div>
                <div class="card-bottom">
                  {{slide[`text_${$i18n.locale}`] || slide.text_ru}}
                </div>
              </div>
            </slide>
          </carousel>
        </div>
      </div>
      <div class="container" style="position: relative">
<!--        <div class="reviews-list" ref="content">-->
<!--          <div class="current-review-card" v-for="item in reviews" @click="currentReview = item">-->
<!--            <div class="card-img">-->
<!--              <el-image-->
<!--                  style="width: 100%; height: 100%"-->
<!--                  src="/images/review-forums-img-back2.png"-->
<!--                  fit="fit"></el-image>-->
<!--            </div>-->
<!--            <div class="card-text">-->
<!--              <div>-->
<!--                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.-->
<!--              </div>-->
<!--              <div class="card-info">-->
<!--                <span>Ахметова Айжан, 9 лет</span>-->
<!--                <span>Участник тестирования</span>-->
<!--                <span>Восточно-Казахстанская область</span>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <carousel :perPage="3" :loop="true" :autoplay="true" :autoplayTimeout="5000" :navigationEnabled="true"
                  :paginationEnabled="false" class="carousel-main">
          <slide v-for="(slide, index) in reviewsSlides" class="current-review-card">
            <div style="padding: 10px" @click="currentReview = slide">
              <div class="card-img">
                <div v-if="extractVideoId(slide.youtube)" class="card-youtube" @click.prevent="openVideoInModal(slide.youtube)">
                  <iframe class="card-youtube iframe-video"
                          :src="`https://www.youtube.com/embed/${extractVideoId(slide.youtube)}`"
                          title="YouTube video player" frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <el-image v-else
                    style="width: 100%; height: 100%"
                    :src="reviewPhotoPath + slide.photo"
                    fit="fit"></el-image>
              </div>
              <div class="card-text">
                <div>
                  {{slide[`text_${$i18n.locale}`] || slide.text_ru}}
                </div>
                <div class="card-info">
                  <span>{{slide[`name_${$i18n.locale}`] || slide.name_ru}}, {{slide.age}} лет</span>
                  <span>{{ $t(`${slide.type}-review`) }}</span>
                  <span>{{slide.region}}</span>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </section>

    <div v-if="isModalOpenYoutube" class="modal" @click="isModalOpenYoutube=false">
      <div class="modal-content">
        <iframe
            :src="currentVideoUrl"
            width="100%"
            height="500"
            frameborder="0"
            allowfullscreen
        ></iframe>
      </div>
    </div>

    <ScrollTopButton />
  </div>
</template>
<script>
import {Carousel, Slide} from 'vue-carousel';
import ScrollTopButton from "../../components/ScrollTop.vue";
// import VueCarousel from 'vue-carousel';

export default {
  components: {
    Carousel,
    Slide,
    ScrollTopButton
  },
  methods: {
    getImagePath(path) {
      if (!path) return

      const formattedPath = encodeURI(path);

      if (formattedPath.startsWith('http://') || formattedPath.startsWith('https://')) {
        return formattedPath;
      } else {
        return this.reviewPhotoPath + formattedPath;
      }
    },
    openVideoInModal(videoUrl) {
      this.currentVideoUrl = `https://www.youtube.com/embed/${this.extractVideoId(videoUrl)}`;
      this.isModalOpenYoutube = true;
    },
    extractVideoId(url) {
      if (!url || typeof url !== 'string') {
        return null;
      }
      const regex = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|watch\?v=|v\/|.+\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:[?&].*)?/;
      const match = url.match(regex);
      return match ? match[1] : null;
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    },
    formattedDate(day) {
      const date = new Date(day);
      return date.toLocaleDateString('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
      });
    },
    scrollRight() {
      this.$refs.content.scrollLeft += 100;
    },
    scrollLeft() {
      this.$refs.content.scrollLeft -= 100;
    },
    pageChange(number) {
      if (window.innerWidth < 992) {
        this.tab = 'comment' + (number + 1);
      } else {
        this.tab = 'comment' + (number + 3);
      }
    },
    paginationClick() {

    },
    slidePre() {
      // console.log(this.$refs.slider);
      this.$refs.slider.$emit('slidePre');
      // this.$emit('slidePre');
    },
    slideNext() {
      this.$refs.slider.$emit('slideNext');
      // this.$emit('slideNext');
    },

  },

  mounted() {
    this.$http.get(window.API_ROOT + '/api/parents/list')
        .then(res => {
          this.parentsList = res.body.data
          this.parentsList.sort( (a, b) => {
            // сортирую, чтобы вначале шли свежие новости
            let dateA = new Date(a.created_at);
            let dateB = new Date(b.created_at);
            return dateB - dateA;
          })

          this.parentsList = this.parentsList.map(item => {
            return {
              ...item,
              image_path: encodeURI(item.image_path)
            };
          });

        })

    this.$http.get(window.API_ROOT + '/api/reviews')
        .then(res => {
          this.reviewsSlides = res.body.data
          this.currentReview = res.body.data[0]
        })

    this.$http.get(window.API_NEWS + '/api/news-list/15?project_id=2')
        .then( res => {
          console.log(res.body)
          this.news = res.body.news.data
        })
    this.$http.get(window.API_ROOT + '/api/events/forum/list')
        .then(res => {
          this.forums = res.body

          // this.displayedItems = this.forumsList.slice(0, 2);
        })



    this.logged = !(localStorage.getItem('token') === null || localStorage.getItem('token') === '');


    if (window.innerWidth < 992) {
      this.tab = 'comment1';
      this.perPageNews = 2
    }

    if (window.innerWidth < 500) {
      this.perPageNews = 1
    }
  },
  filters: {
    date:function (value){
      return  new Date(value).toLocaleDateString();
    },
    Numerize: function (value) {
      return value.replace(/<\/?[^>]+>/g,'').replace(/&nbsp;/g,' ');
    }
  },
  data() {
    return {
      currentVideoUrl: '',
      isModalOpenYoutube: false,
      reviewsSlides: [],
      reviewPhotoPath: window.API_ROOT + '/storage/',
      getTestedLink: localStorage.getItem('token') ? "/testing" : "/signin",
      getAptitudeTestsLink: localStorage.getItem('token') ? "/quizzes-list" : "/signin",
      currentTab: 'teachers',
      parentsList: [],
      perPageNews: 1,
      currentReview: "",
      currentName: this.$t('main-page.qabilet-in-numbers'),
      tab: 'comment3',
      map: {},
      regions: [],
      usersCount: 0,
      diagnostickTests: 0,
      profOrientationTests: 0,
      consultationsCount: 0,
      regionalCenters: 0,
      psychCount: 0,
      allUsersCount: 0,
      allDiagnostickTests: 0,
      allProfOrientationTests: 0,
      allConsultationsCount: 0,
      allRegionalCenters: 0,
      allPsychCount: 0,
      logged: false,
      news: [],
      backUrlImg: window.API_NEWS + '/uploads/',
      backUrlNews: window.API_NEWS + '/one-news/',
      forums: [],
    }
  }
}
</script>
<style>

.iframe-video {
  pointer-events: none;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  width: 100%;
  height: 60%;
  max-width: 800px;
}

.modal iframe {
  width: 100%;
  height: 100%;
}

.news-main-mobile,
.reasons-mobile,
.mobile-line-top,
.useful-main-mobile,
.carousel-main-mobile,
.carousel-mobile

{
  display: none;
}

.reviews-main .card-img img {
  border-radius: 20px;
}

@media screen and (max-width: 1200px) {
  .tests-new .card {
    height: 260px;
  }

  .news-item-right {
    max-height: 110px;
  }

  .news-item-left-title {
    font-size: 14px;
  }

  .news-item-left-text {
    font-size: 14px;
  }

  .news-item-right-label {
    font-size: 10px;
    padding: 5px 10px;
  }

  .for-parents .cards-block {
    gap: 50px;
  }

  .for-parents .card-item .card-item-title {
    font-size: 16px;
  }

  .for-parents .card-item .card-item-text {
    font-size: 14px;
  }

  .for-parents .card-item-big .card-item-text {
    font-size: 14px;
  }

  .for-parents .card-item-big .card-item-title {
    font-size: 16px;
  }

  .current-review-card .card-img {
    height: 400px;
  }

  .carousel-main .VueCarousel-navigation-prev {
    display: none;
  }

  .carousel-main .VueCarousel-navigation-next {
    display: none;
  }
}

@media screen and (max-width: 992px) {
  .reviews-main .current-review {
    margin-top: 30px;
  }

  .news-main-desktop {
    display: none;
  }

  .news-main-mobile {
    display: block;
  }

  .news-main-mobile .section-title {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .mobile-line-top {
    display: block;
  }

  .carousel-mobile {
    display: block;
  }

  .mobile-line-top {
    display: block;
  }

  .carousel-mobile-item {
    padding-right: 20px;
  }

  .carousel-mobile-item .el-image {
    height: 205px !important;
    border-radius: 20px;
  }

  .carousel-mobile-item .date {
    font-weight: 400;
    font-size: 14px;
    color: #9497A1;
    margin: 20px 0 12px 0;
  }

  .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .carousel-mobile-item .more-btn {
    font-weight: 600;
    font-size: 16px;
    color: #185CFF;
    margin: 12px 0;
    position: relative;
    display: inline-block;
    padding-right: 30px;
  }

  .carousel-mobile-item .more-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/images/icon_arrow_right.svg");
  }

  .carousel-main-mobile {
    display: block;
    margin-top: 70px;
  }

  .current-review-card-mobile {
    padding: 35px;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  }

  .current-review-card-mobile .card-img {
    width: 50px;
    height: 50px;
  }

  .current-review-card-mobile .card-img .el-image {
    border-radius: 50%;
  }

  .current-review-card-mobile .card-top {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .card-top .card-info p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #9497A1;
  }

  .card-top .card-info .name {
    color: #32436D;
  }

  .card-bottom {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #9497A1;
  }

  .reviews-main .reviews-title {
    text-align: left;
    margin-top: 0;
  }

  .reviews-main .reviews-text {
    text-align: left;
  }





}

/*
@media screen and (max-width: 992px) {

  .carousel-main-mobile {
    display: block;
    margin-top: 70px;
  }

  .carousel-mobile {
    display: block;
  }

  .mobile-line-top {
    display: block;
  }

  .description-right {
    display: none;
  }

  .description-left-back {
    display: none;
  }

  .description-left {
    width: 100%;
  }

  .description-title {
    font-size: 30px;
    width: 80%;
  }

  .description-subtitle {
    font-size: 16px;
    width: 80%;
  }

  .description-block {
    height: 430px;
    border-radius: 0;
  }

  .description-block::before {
    width: 100%;
    height: 81px;
    content: "";
    position: absolute;
    top: 440px;
    left: 0;
    border-radius: 50%;
    background: linear-gradient(to right, #32436D, #1A84B2, #02C7FA);

    display: none;
  }

  .tests-new .section-title {
    display: none;
  }

  .tests-new {
    background-image: none;
    margin-top: 20px;
  }

  .tests-new .card {
    height: 190px;
    padding: 60px 0 0 0;
  }

  .tests-new .title {
    font-size: 20px;
  }

  .tests-new .card-link {
    margin-top: auto;
    width: 100%;
    border-radius: 0;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.15);
    border: none;
  }

  .circle-blue, .circle-navy {
    display: none;
  }

  .reasons-desktop {
    display: none;
  }

  .reasons-mobile {
    padding: 0 15px;
    display: block;
  }

  .reasons-mobile .reason-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .reasons-mobile .title {
    text-align: left;
    font-size: 30px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 30px;
  }

  .reason-item-top {
    font-size: 20px;
    font-weight: 700;
    color: #32436D;
    position: relative;
    padding-left: 50px;
  }

  .reason-item-top::before {
    width: 32px;
    height: 32px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../public/images/reasons-item-back.svg");
    background-size: 50% 50%;
    background-position: center ;
    background-repeat: no-repeat;
    box-shadow: 0px 8.35px 24.5px 0px rgba(13, 15, 28, 0.08);
    border-radius: 50%;
  }

  .reason-item-bottom {
    font-size: 16px;
    font-weight: 400;
    color: #9497A1;
    line-height: 28px;
  }

  .news-main-desktop {
    display: none;
  }

  .news-main-mobile {
    display: block;
  }

  .news-main-mobile .section-title {
    font-size: 30px;
    font-weight: 800;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .parents-list-mobile .carousel-mobile-item {
    display: inline-block;
    text-decoration: none;
    padding-right: 20px;
  }

  .parents-list-mobile .carousel-mobile-item .el-image {
    border-radius: 10px;
  }

  .carousel-mobile-item {
    padding-right: 20px;
  }

  .carousel-mobile-item .date {
    font-weight: 400;
    font-size: 14px;
    color: #9497A1;
    margin: 20px 0 12px 0;
  }

  .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
  }

  .carousel-mobile-item .more-btn {
    font-weight: 600;
    font-size: 16px;
    color: #185CFF;
    margin: 12px 0;
    position: relative;
    display: inline-block;
    padding-right: 30px;
  }

  .carousel-mobile-item .more-btn::after {
    content: "";
    position: absolute;
    top: 50%;
    right: -6px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../public/images/icon_arrow_right.svg");
  }

  .tests-new {
    padding-bottom: 0;
  }

  .useful-main {
    background-image: none;
  }

  .useful-main .mobile-line-top {
    margin-bottom: 30px;
  }

  .useful-main .section-title {
    text-align: left;
    margin-bottom: 30px;
  }

  .useful-main .useful-title {
    text-align: left;
    font-size: 26px;
    width: 80%;
  }

  .useful-main .useful-text {
    text-align: left;
    width: 80%;
  }

  .useful-main-mobile {
    display: block;
  }

  .useful-main-mobile .title {
    font-weight: 700;
    font-size: 20px;
    color: #9497A1;
    margin: 30px 0;
  }

  .useful-main-mobile .text {
    font-weight: 400;
    font-size: 20px;
    color: #9497A1;
  }

  .useful-main-mobile .open-btn {
    display: inline-block;
    width: 100%;
    background-color: #02C7FA;
    color: #FFF;
    text-align: center;
    padding: 20px;
    border-radius: 6px;
    margin-top: 30px;
  }

  .for-parents .section-title {
    text-align: left;
  }

  .for-parents .mobile-line-top {
    margin-bottom: 30px;
  }

  .for-parents .cards-block {
    display: none;
  }

  .for-parents .events-link {
    display: none;
  }

  .for-parents .carousel-mobile-item .title {
    font-weight: 700;
    font-size: 20px;
    color: #32436D;
    margin-bottom: 16px;
  }

  .for-parents .carousel-mobile-item .text {
    font-weight: 400;
    font-size: 16px;
    color: #9497A1;
  }

  .forums .mobile-line-top {
    margin-bottom: 30px;
  }

  .forums .section-title {
    text-align: left;
    margin-bottom: 30px;
  }

  .forums-cards {
    margin-top: 0;
    flex-direction: row;
  }

  .forums .col-lg-6 {
    padding: 0;
  }

  .reviews-main .reviews-title {
    text-align: left;
    margin-top: 0;
  }

  .reviews-main .reviews-text {
    text-align: left;
  }

  .carousel-main {
    display: none;
  }

  .current-review-card {
    display: none;
  }

  .reviews-main .current-review {
    background-image: none;
  }

  .current-review-card-mobile {
    padding: 35px;
    background: #FFF;
    border-radius: 6px;
    box-shadow: 0px 4px 12px 0px rgba(12, 68, 204, 0.1);
  }

  .current-review-card-mobile .card-img {
    width: 50px;
    height: 50px;
  }

  .current-review-card-mobile .card-img .el-image {
    border-radius: 50%;
  }

  .truncate-title-slide {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .truncate-text-slide {
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .current-review-card-mobile .card-top {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;
  }

  .card-top .card-info p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #9497A1;
  }

  .card-bottom {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    color: #9497A1;
  }

  .carousel-main-mobile .VueCarousel-navigation-prev {
    color: #32436D !important;
    background: #C9DCEC;
    width: 48px;
    height: 40px;
    background-image: none;
    position: absolute;
    left: 50px !important;
    top: -33px;
    cursor: pointer;
    border-radius: 14px;
  }

  .carousel-main-mobile .VueCarousel-navigation-next {
    color: #32436D !important;
    background: #C9DCEC;
    width: 48px;
    height: 40px;
    background-image: none;
    position: absolute;
    left: 20px !important;
    top: -33px;
    cursor: pointer;
    border-radius: 14px;
  }

}

@media screen and (max-width: 500px) {
  .forums-cards {
    flex-direction: column;
  }
}

#paper path:hover {
  fill: #1c5fc2;
  cursor: pointer;
}

#paper path {
  stroke: #fff;
  stroke-width: 1px;
  transition: all .3s linear;
}

.text-transition {
  transition: all .3s linear;
}

.slide-pre,
.slide-next {
  position: absolute;
  top: calc(50% - 25px);
  border: none;
  background-color: transparent;
}

.slide-pre:active,
.slide-pre:hover,
.slide-next:active,
.slide-next:hover {
  outline: none;
  cursor: pointer;
}

.slide-next {
  right: -50px;
}

.slide-pre {
  left: -50px;
}

.breadcrumb--main-page {
  display: none !important;
}

@media (max-width: 991px) {
  .breadcrumb--main-page {
    display: block !important;
  }

  .nur-sultan {
    left: 60%;
    top: 28%;
  }

  .almaty {
    right: 26%;
    top: 78%;
  }

  .shymkent {
    left: 54%;
    bottom: 31px;
  }
}

@media (max-width: 767px) {
  .nur-sultan {
    left: 65%;
    top: 27%;
  }


  .almaty {
    right: 15%;
    top: 76%;
  }

  .shymkent {
    left: 57%;
    bottom: 46px;
  }
}

@media (max-width: 575px) {
  .nur-sultan {
    left: 56%;
    top: 24%;
  }


  .almaty {
    right: 31%;
    top: 43.7%;

  }

  .shymkent {
    left: 52%;
    bottom: 253px;
  }
}

@media (max-width: 520px) {
  .nur-sultan {
    left: 57%;
    top: 24%;
  }


  .almaty {
    right: 29%;
    top: 43.7%;

  }

  .shymkent {
    left: 53%;
    bottom: 253px;
  }
}

@media (max-width: 480px) {
  .nur-sultan {
    left: 57%;
    top: 21%;
  }


  .almaty {
    right: 27%;
    top: 42.7%;

  }

  .shymkent {
    left: 52%;
    bottom: 226px;
  }
}

@media (max-width: 449px) {
  .nur-sultan {
    left: 59%;
    top: 21%;
  }


  .almaty {
    right: 25%;
    top: 42.7%;

  }

  .shymkent {
    left: 54%;
    bottom: 226px;
  }
}

@media (max-width: 410px) {
  .nur-sultan {
    left: 59%;
    top: 21%;
  }


  .almaty {
    right: 24%;
    top: 42.7%;

  }

  .shymkent {
    left: 54%;
    bottom: 226px;
  }
}

@media (max-width: 375px) {
  .nur-sultan {
    left: 62%;
    top: 21%;
  }


  .almaty {
    right: 21%;
    top: 42.7%;

  }

  .shymkent {
    left: 56%;
    bottom: 226px;
  }
}

 */
</style>
